const CashFlow = {
	method: {
		CASH: 1,
		TRASNFER: 3,
		COD: 11,
		VNPAY: 15,
		CARD: 19,
		// VOUCHER: 9999,
	},
};

export default CashFlow;
