import { useEffect, useState } from "react";
import { getCashFlowreceipts } from "../api/shop/cashflowreceipts";
import {
	CashflowreceiptJson,
	CashflowreceiptsStatus,
} from "@/types/Cashflowreceipts.type";
import { useQuery } from "@tanstack/react-query";
import { getDataError } from "../config/apiHandler";
import BaseApi from "@/common/api/BaseApi";

type Props = {
	orderId: string | number;
	skip?: boolean;
};
const useCashFlows = ({ orderId, skip = false }: Props) => {
	const [cashFlows, setCashFlows] = useState<CashflowreceiptJson[]>([]);

	const {
		isLoading: loadingCashFlows,
		data: dataCashFlows,
		refetch,
		error,
	} = useQuery({
		queryKey: ["cashFlows", orderId],
		queryFn: async () => {
			const cashFlowsResponse = await getCashFlowreceipts({
				source_id: orderId,
			});
			return cashFlowsResponse;
		},
		// staleTime: 0,
		retry: 1,

		enabled: !!orderId && !skip,
		throwOnError(error, query) {
			throw getDataError(error);
		},

		// onSuccess: (dataCashFlows:any) => {
		// 	if (!dataCashFlows?.data) {
		// 		throw BaseApi.handleErrorCore({
		// 			errors: ["no_data_cashFlow"],
		// 			status: 406,
		// 		});
		// 	}
		// 	setCashFlows([
		// 		{
		// 			cashflow_group: 101,
		// 			company_id: 1,
		// 			creator_id: 3,
		// 			date_affected: 0,
		// 			date_created: 0,
		// 			date_modified: 0,
		// 			direction: 5,
		// 			id: 414,
		// 			identifier: "DH20240830000005",
		// 			method: 1,
		// 			name: "DH20240830000005",
		// 			note: "",
		// 			source: 0,
		// 			source_id: 0,
		// 			status: 1,
		// 			store_id: 310499,
		// 			target: 3,
		// 			target_id: 84,
		// 			target_label: "",
		// 			value: 8100000,
		// 		},
		// 	]);
		// },
	});

	useEffect(() => {
		if (dataCashFlows) {
			setCashFlows(
				dataCashFlows.data.items
					.filter((item) => item.status !== CashflowreceiptsStatus.CANCEL)
					.sort((a, b) => b.date_created - a.date_created)
			);
		}
		return () => {
			setCashFlows([]);
		};
	}, [dataCashFlows]);

	return { loadingCashFlows, error, cashFlows, setCashFlows, refetch };
};

export default useCashFlows;
