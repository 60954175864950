import { callApiV2 } from "@/lib/config/Api";
import {
	DeliveryCollectionJson,
	DeliveryInternalAddEditJson,
} from "@/types/Delivery.type";
import Helper from "@/utils/helper";

export async function createDeliveries(data: DeliveryInternalAddEditJson) {
	const dataPost = Helper.convertParams(data);
	const res = await callApiV2("/deliveries", "post", { data: dataPost });
	return res;
}

export async function getDeliveries(props: { resource_id: number | string }) {
	return await callApiV2<DeliveryCollectionJson>("/deliveries", "get", {
		params: props,
	});
}

export async function updatePriceShipping() {}
