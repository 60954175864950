import BaseCollection from "@/common/collections/BaseCollection";
import { BaseCollectionJson } from "@/common/types/BaseCollection.type";

export enum CashflowreceiptsStatus {
	DRAFT = 1,
	PENDING,
	ACCEPT,
	SUCCSECC = 9,
	CANCEL = 11,
	NEW = 12,
}

export enum CashflowreceiptsType {
	RECEIPT = 5,
	PAYMENT = 10,
}
export enum CashflowreceiptsMethod {
	CASH = 1,
	TRASNFER = 3,
	COD = 11,
	VNPAY = 15,
	CARD = 19,
	VOUCHER = 9999,
}

export interface CashflowreceiptsCreateInput {
	status: CashflowreceiptsStatus;
	direction: CashflowreceiptsType | string;
	source_id: number | string;
	source: number;
	value: number;
	target: number;
	target_id: number;
	company_id: number;
	creator_id: number;
	identifier: string;
	name: string;
	note: string;
	method: CashflowreceiptsMethod;
	date_affected: number;
	cashflow_group: number;
	store_id: number;
}

export type CashflowreceiptJson = {
	cashflow_group: string | number;
	company_id: number;
	creator_id: number;
	date_affected: number;
	date_created: number;
	date_modified: number;
	direction: string | number;
	id: number;
	identifier: string;
	method: number;
	name: string;
	note: string;
	source: number;
	source_id: number | string;
	status: CashflowreceiptsStatus;
	store_id: number;
	target: number;
	target_id: number;
	target_label: string;
	value: number;
};

export type CashflowreceiptList = BaseCollectionJson<CashflowreceiptJson>;
