import CashFlow from "@/common/constants/cashflow";
import {
  CartOrderJson,
  CartPromotion,
  IsUse,
  ProductCartAddEditJson,
} from "@/types/Cart.type";
import {
  CashflowreceiptJson,
  CashflowreceiptsMethod,
} from "@/types/Cashflowreceipts.type";
import { CustomerQuickRegisterJson } from "@/types/Customer.type";
import { OrderJson } from "@/types/Orders.type";
import { PaymentDataSubmit } from "@/types/Payment.type";
import { ProductJson } from "@/types/Product.type";
import { PromotionJson } from "@/types/Promotion.type";
import Helper from "@/utils/helper";

type Json = {
  order: OrderJson;
  defaultParams?: Object;
};
class OrderRepo {
  private order: OrderJson;
  private defaultParams: Object;
  private static instance: OrderRepo;

  private constructor(json: Json) {
    this.order = json.order;
    this.defaultParams = json.defaultParams || {};
  }

  public static getInstance(json: Json): OrderRepo {
    if (!OrderRepo.instance) {
      OrderRepo.instance = new OrderRepo(json);
    }
    return OrderRepo.instance;
  }

  static convertPromotionToOrderPromotion(
    promotions: PromotionJson[],
    quantity: number = 1,
    isUse?: IsUse,
    productId?: ProductJson["id"]
  ): CartPromotion[] {
    return promotions
      .filter((pro) => pro.status)
      .map((p) => {
        return Helper.convertParams({
          is_use: isUse ?? p.is_use,
          code: p.code,
          product_id: productId,
          promotion_detail: p,
          promotion_id: p.campaign_info.id,
          sale_promotion_id: p.id,
          item_quantity: quantity,
        });
      }) as CartPromotion[];
  }

  static convertCashFlowsFromPaymentMethod() {}

  static convertPaymentDataToQuickRegisterCustomer(
    data: Partial<PaymentDataSubmit>
  ) {
    const {
      billing_province,
      billing_district,
      billing_ward,
      billing_phone,
      billing_country,
      billing_full_name,
      billing_address,
    } = data;
    const submitData: CustomerQuickRegisterJson = {
      full_name: billing_full_name?.trim() || "",
      phone: billing_phone?.trim() || "",
      address: billing_address?.trim() || "",
      province: billing_province || 0,
      district: billing_district || 0,
      ward: billing_ward || 0,
      country: billing_country || 0,
    };
    return submitData;
  }

  //////////////////////////////////
  // calc

  static calcPriceOrderItem(item: CartOrderJson, q?: number) {
    const quantity = q ?? 1;
    const product = item.product_json;
    const discount = item.price_discount / item.item_quantity;
    const p_price_preview = product.pricePreview;
    const p_compare = product.compare_at_price;
    const p_price = product.price;
    const final = () => {
      let result = product.price;
      if (discount) {
        result = item.price_unit_final;
      }
      return result * quantity;
    };
    const compare = () => {
      let result = p_compare;

      if (p_compare <= 0 && discount > 0) {
        result = p_price;
      }

      if (p_compare <= p_price) {
        result = 0;
      }

      return result * quantity;
    };
    const dataFinal = final();
    const dataCompare = compare();

    const percent = () => {
      const compareInit = dataCompare / quantity;
      const final = p_price_preview?.price_final || dataFinal;

      if (compareInit > 0) {
        if (final !== undefined) {
          return compareInit <= final
            ? 0
            : ((compareInit - final) / compareInit) * 100;
        }
        return ((compareInit - p_price) / compareInit) * 100;
      }

      if (final !== undefined) {
        return p_price <= final ? 0 : ((p_price - final) / p_price) * 100;
      }

      return 0;
    };

    const dataPercent = percent();
    return {
      priceFinal: dataFinal,
      priceCompare: dataCompare,
      percent: dataPercent,
    };
  }

  public calcValueCashFlow(cash: CashflowreceiptJson[]) {
    // const;
  }

  //convert

  static convertProductToProductOrder(
    productId: ProductJson["id"],
    quantity: number = 1,
    promotionsOrder: CartPromotion[] = []
  ): ProductCartAddEditJson {
    return {
      item_quantity: quantity,
      product_id: productId,
      promotions: promotionsOrder,
    };
  }

public  static  convertPromotionForCartItem(
    cartItem: CartOrderJson,
    quantity: number
  ): CartPromotion[] {
    const promotionInItem = cartItem.promotions;
    return promotionInItem.map((p) => ({
      ...p,
      item_quantity: quantity,
      is_use: 1,
    }));
  }

  public  static   convertDetailsForUpdateQuantityItem(
    cartItem: CartOrderJson,
    quantity: number
  ): ProductCartAddEditJson | null {
    // const cartItem = this.order.details.data.find(
    // 	(item) => item.id === cartItemId
    // );
    // if (!cartItem) return null;
    return {
      item_quantity: quantity,
      product_id: cartItem.product_id,
      id: cartItem.id,
      is_use: cartItem.is_use,
      promotions: this.convertPromotionForCartItem(cartItem, quantity),
    };
  }
  //utils

  public static utilGetLableMethodCashFlow(value: number) {
    switch (value) {
      case CashflowreceiptsMethod.CASH:
        return "Tiền mặt";
      case CashflowreceiptsMethod.CARD:
        return "Thẻ";
      case CashflowreceiptsMethod.COD:
        return "COD";
      case CashflowreceiptsMethod.TRASNFER:
        return "Chuyển khoản";
      case CashflowreceiptsMethod.VNPAY:
        return "VNPay";
      case CashflowreceiptsMethod.VOUCHER:
        return "Voucher";

      default:
        return "";
    }
  }

  //containts

  static containtMethodCashFlow() {
    return Object.values(CashFlow.method).map((method) => {
      return {
        label: this.utilGetLableMethodCashFlow(method),
        value: method,
      };
    });
  }
}

export default OrderRepo;
