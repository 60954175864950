import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { DeliveryJson } from "@/types/Delivery.type";
import { getDeliveries } from "../api/shop/delivery";
import { getDataError } from "../config/apiHandler";

type Props = {
	orderId: string | number;
	skip?: boolean;
};
const useDeliveries = ({ orderId, skip = false }: Props) => {
	const [deliveries, setDeliveries] = useState<DeliveryJson[]>([]);

	const {
		isLoading: loadingDeliveries,
		data,
		error,
	} = useQuery({
		queryKey: ["deliveries", orderId],
		queryFn: async () => {
			const deliveryResponse = await getDeliveries({ resource_id: orderId });
			return deliveryResponse;
		},
		staleTime: 60 * 1000,
		enabled: !!orderId && !skip,
		throwOnError(error, query) {
			throw getDataError(error);
		},
		// onSuccess: (dataDeliveries) => {
		// 	if (!dataDeliveries?.data) {
		// 		throw BaseApi.handleErrorCore({
		// 			errors: ["no_data_delivery"],
		// 			status: 406,
		// 		});
		// 	}

		// 	setDeliveries(dataDeliveries.data);
		// },
	});

	useEffect(() => {
		if (data) {
			setDeliveries(data.data.items);
		}
	}, []);

	return { loadingDeliveries, error, deliveries, setDeliveries };
};

export default useDeliveries;
