import {
	CashflowreceiptJson,
	CashflowreceiptsMethod,
} from "@/types/Cashflowreceipts.type";
import { PaymentMethod } from "@/types/Payment.type";

class CheckoutRepo {
	///////////////////////////////
	//calc

	///////////////////////////////

	static swtichPaymetMethodToNumber(method: PaymentMethod | number) {
		if (typeof method === "number") return method;

		switch (method) {
			case "card":
				return CashflowreceiptsMethod.CARD;

			case "transfer":
				return CashflowreceiptsMethod.TRASNFER;
			case "cash":
				return CashflowreceiptsMethod.CASH;
			case "cod":
				return CashflowreceiptsMethod.COD;
			case "vnpay":
				return CashflowreceiptsMethod.VNPAY;

			default:
				return 0;
		}
	}

	static convertCashFlowsFromPaymentMethod(
		data: { amount: number; method: number | PaymentMethod }[]
	): Partial<CashflowreceiptJson>[] {
		return data.map(({ amount, method }) => {
			return {
				cashflow_group: 101,
				direction: 5,
				method: CheckoutRepo.swtichPaymetMethodToNumber(method),
				value: amount,
			};
		});
	}
}

export default CheckoutRepo;
