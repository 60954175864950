import { callApiV2 } from "@/lib/config/Api";
import {
	CashflowreceiptJson,
	CashflowreceiptList,
	CashflowreceiptsCreateInput,
} from "@/types/Cashflowreceipts.type";
import Helper from "@/utils/helper";

export async function createCashflowreceipts(
	payload: Partial<CashflowreceiptJson>
) {
	const dataPost = Helper.convertParams(payload);
	const res = await callApiV2<CashflowreceiptJson>(
		"/cashflowreceipts",
		"post",
		{
			data: dataPost,
		}
	);
	return res.data;
}

export async function createCashflowreceiptsPublic(
	payload: Partial<CashflowreceiptJson>
) {
	const dataPost = Helper.convertParams(payload);
	const res = await callApiV2<CashflowreceiptJson>(
		"/cashflowreceipts/public",
		"post",
		{
			data: dataPost,
		}
	);
	return res.data;
}

export async function updateCashflowreceipts(
	payload: Partial<CashflowreceiptJson>
) {
	const data: any = { ...payload };

	delete data.id;
	const dataPost = Helper.convertParams(data);
	const res = await callApiV2<CashflowreceiptJson>(
		`/cashflowreceipts/${payload.id}`,
		"put",
		{
			data: dataPost,
		}
	);
	return res.data;
}

export async function getCashFlowreceipts({
	source_id,
}: {
	source_id: string | number;
}) {
	return callApiV2<CashflowreceiptList>(`/cashflowreceipts`, "get", {
		params: { source_id: source_id.toString(), source: 1 },
	});
}
